const getPositions = (slides) =>
  slides.map((slide) => {
    const rect = slide.el.getBoundingClientRect();
    return {
      slideType: slide.slideType,
      start: rect.top + document.documentElement.scrollTop,
      end: rect.bottom + document.documentElement.scrollTop,
    };
  });

// Double check (IntersectionObserver, onscroll)
// due to https://stackoverflow.com/questions/59327188/intersection-observer-loses-entries
export default function animateSlides({
  slides,
  slideAttribute,
  onInersect,
  onDestroy = Function.prototype,
}) {
  let lastSlideValue = null;
  let positions;
  const calculatePositions = () => {
    positions = getPositions(slides);
  };
  const onScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    positions.forEach(({ start, end, slideType }) => {
      if (scrollTop > start && scrollTop < end) {
        if (lastSlideValue !== slideType) {
          onInersect({ currentType: slideType });
          lastSlideValue = slideType;
        }
      }
    });
  };
  const imageObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        onInersect({
          currentType: entry.target.getAttribute(slideAttribute),
        });
      }
    });
  });

  calculatePositions();
  window.addEventListener("scroll", onScroll);
  slides.forEach((slide) => {
    imageObserver.observe(slide.el);
  });

  return {
    recalculate: calculatePositions,
    destroy: () => {
      slides.forEach((slide) => {
        imageObserver.unobserve(slide.el);
      });
      window.removeEventListener("scroll", onScroll);
      onDestroy();
    },
  };
}
