import "intersection-observer";
import likely from "ilyabirman-likely";
import LazyLoad from "vanilla-lazyload";
import { initGradient } from "./js/gradient";
import { initSlides } from "./js/slides";
import { initPagePreview } from "./js/preview";
import { initTwitterCounter } from "./js/twitterCounter";
import { initMobileVersion } from "./js/mobile";

// Share buttons
likely.initiate();
initTwitterCounter();

// bg
initGradient({
  canvas: document.querySelector(".page__bg"),
});

// init slides
let initedSlides;
const slides = Array.from(document.querySelectorAll(".slide")).map((el) => ({
  el,
  slideType: el.getAttribute("data-slide"),
  img: el.querySelector(".slide__img") || { style: {} },
  imageWrapper: el.querySelector(".slide__image") || { style: {} },
  imageRatio: el.querySelector(".slide__image_ratio") || { style: {} },
}));
const getIsMobile = () => window.innerWidth < 960;
const startSlides = () => {
  const isMobile = getIsMobile();
  if (isMobile || initedSlides) return;

  initedSlides = initSlides({
    slideAttribute: "data-slide",
    slides,
  });
};

// on resize
let isMobileState;
const changeDeviceSize = () => {
  const isMobile = getIsMobile();
  if (isMobile !== isMobileState) {
    if (isMobile) {
      if (initedSlides) {
        initedSlides.destroy();
        initedSlides = null;
      }
    } else {
      startSlides();
    }

    isMobileState = isMobile;

    window.scrollTo(0, 0);
  }
};

window.addEventListener("resize", changeDeviceSize);

if (getIsMobile()) {
  // start without animation
  changeDeviceSize();

  initMobileVersion({ slides });
}

// animated preview
initPagePreview({
  slideImagesSelector: "object.slide__img",
  onReady: startSlides,
});

// lazy load
new LazyLoad({
  threshold: window.innerHeight * 2, // px
  elements_selector: "img",
  callback_loaded: () => {
    if (initedSlides) {
      initedSlides.recalculate();
    }
  },
});

window.scrollTo(0, 0);
