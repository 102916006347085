export function initTwitterCounter() {
  fetch(
    `https://opensharecount.com/count.json?url=${window.CONFIG.productionUrl}`
  )
    .then((response) => response.json())
    .then(({ count = 0 } = {}) => {
      if (count > 0) {
        document
          .querySelectorAll(".likely__button_twitter .likely__counter")
          .forEach((node) => (node.innerHTML = count));
      }
    });
}
