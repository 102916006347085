export const CONFIG = {
  gradient: {
    speed: 0.4,
    colors: {
      color0: "#000111",
      color1: "#42004d",
      color2: "#150096",
      color3: "#37006e",
    },
  },
};
