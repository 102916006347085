import { SLIDE_TYPES } from "../constants";

export function initMobileVersion({ slides }) {
  slides.forEach(({ imageWrapper, imageRatio, slideType }) => {
    if (!imageWrapper.scrollWidth || !imageRatio.style) return;

    switch (slideType) {
      case SLIDE_TYPES.TITLES:
      case SLIDE_TYPES.TITLES_GRID:
      case SLIDE_TYPES.AIRPORTS:
        imageRatio.style.width = "230vw";
        break;
      case SLIDE_TYPES.ART:
        imageRatio.style.width = "320vw";
        break;
      case SLIDE_TYPES.LEGEND:
        imageRatio.style.width = "200vw";
        break;
      case SLIDE_TYPES.FUTURE:
        imageRatio.style.width = "100%";
        break;
      default:
        imageRatio.style.width = "135vw";
        break;
    }

    switch (slideType) {
      case SLIDE_TYPES.TITLES:
      case SLIDE_TYPES.TITLES_GRID:
        imageWrapper.scrollTo(imageWrapper.scrollWidth / 3.8, 0);
        break;
      case SLIDE_TYPES.AIRPORTS:
        imageWrapper.scrollTo(imageWrapper.scrollWidth / 3.3, 0);
        break;
      case SLIDE_TYPES.ART:
        imageWrapper.scrollTo(imageWrapper.scrollWidth / 2.3, 0);
        break;
      case SLIDE_TYPES.LEGEND:
        imageWrapper.scrollTo(imageWrapper.scrollWidth * 0.01, 0);
        break;
      default:
        imageWrapper.scrollTo(imageWrapper.scrollWidth, 0);
        break;
    }
  });
}
