import { CONFIG } from "../../config";
import hexToFloatRGB from "../../utils/hexToFloatRGB";

export function initGradient({
  canvas,
  v = `
    attribute vec4 position;
    void main() {
      gl_Position = position;
    }
  `,
  f = `
      precision mediump float;
  
      uniform float u_time;
      uniform float u_scroll_percent;
      uniform vec2 u_resolution;
  
      uniform vec3 u_color_0;
      uniform vec3 u_color_1;
      uniform vec3 u_color_2;
      uniform vec3 u_color_3;
  
      float speed = u_time;

      float rand(vec2 n) {
        return fract(cos(dot(n, vec2(1.0, 1.5))) * 20.0);
      }

      float noise(vec2 p) {
        vec2 ip = floor(p);
        vec2 u = fract(p);
        u = u * u * (3.0 - 2.0 * u);
  
        float res = mix(
          mix(rand(ip), rand(ip + vec2(1.0, 0.0)), u.x),
          mix(rand(ip + vec2(0.0, 1.0)), rand(ip + vec2(1.0, 1.0)), u.x), u.y);
        return res;
      }

      void main() {
        vec2 st = gl_FragCoord.xy * 2.0 / u_resolution.xy;
  
        float s1 = noise(1.0 * st - speed);
        float s2 = noise(1.0 * st + speed + 1.0 * s1);
  
        vec3 xmixColor = mix(u_color_1, u_color_2, s1);
        vec3 ymixColor = mix(u_color_0, u_color_3, s2);
        vec3 mixColor = xmixColor * 0.6 + ymixColor * 0.8;
        vec3 finalColor = mix(u_color_0, mixColor, s2);
  
        gl_FragColor = vec4(finalColor, 1.0);
      }
    `,
}) {
  const gl = canvas.getContext("webgl", { preserveDrawingBuffer: true });
  const program = gl.createProgram();
  const vs = gl.createShader(gl.VERTEX_SHADER);
  const fs = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(vs, v);
  gl.shaderSource(fs, f);
  gl.compileShader(vs);
  gl.compileShader(fs);
  gl.attachShader(program, vs);
  gl.attachShader(program, fs);
  gl.linkProgram(program);
  gl.useProgram(program);

  gl.bindAttribLocation(program, 0, "position");
  gl.enableVertexAttribArray(0);

  const draw = () => {
    var vertices = [
      1.0,
      1.0,
      0.0,
      -1.0,
      1.0,
      0.0,
      1.0,
      -1.0,
      0.0,
      -1.0,
      -1.0,
      0.0,
    ];
    gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);
    gl.vertexAttribPointer(0, 3, gl.FLOAT, false, 0, 0);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    gl.flush();
  };

  const uTimeLocation = gl.getUniformLocation(program, "u_time");
  const uResolution = gl.getUniformLocation(program, "u_resolution");
  const uScroll = gl.getUniformLocation(program, "u_scroll_percent");

  const uColor0 = gl.getUniformLocation(program, "u_color_0");
  const uColor1 = gl.getUniformLocation(program, "u_color_1");
  const uColor2 = gl.getUniformLocation(program, "u_color_2");
  const uColor3 = gl.getUniformLocation(program, "u_color_3");

  gl.uniform3f(uColor0, ...hexToFloatRGB(CONFIG.gradient.colors.color0));
  gl.uniform3f(uColor1, ...hexToFloatRGB(CONFIG.gradient.colors.color1));
  gl.uniform3f(uColor2, ...hexToFloatRGB(CONFIG.gradient.colors.color2));
  gl.uniform3f(uColor3, ...hexToFloatRGB(CONFIG.gradient.colors.color3));

  gl.uniform2f(uResolution, gl.canvas.width, gl.canvas.height);

  function renderLoop(timeStamp) {
    gl.uniform1f(uTimeLocation, (CONFIG.gradient.speed * timeStamp) / 1000.0);
    gl.uniform1f(uScroll, window.scrollY / window.innerHeight);
    draw();
    window.requestAnimationFrame(renderLoop);
  }

  window.requestAnimationFrame(renderLoop);
}
