const CSS_CLASS_PAGE_PREVIEW = "page_preview";
const CSS_CLASS_PAGE_READY = "page_ready";

function lazyLoadMapImages(cssSelector) {
  const images = Array.from(document.querySelectorAll(cssSelector));
  images.forEach((image) => {
    image.data = image.getAttribute("data-src");
  });
}

export function initPagePreview({ slideImagesSelector, onReady }) {
  window.scrollTo(0, 0);
  lazyLoadMapImages(slideImagesSelector);

  // preview
  if (!document.body.classList.contains(CSS_CLASS_PAGE_READY)) {
    document.body.classList.add(CSS_CLASS_PAGE_PREVIEW);
    window.scrollTo(0, 0);
  }

  onReady();
  document.body.classList.add(CSS_CLASS_PAGE_READY);
}
