export const SLIDE_TYPES = {
  PREVIEW: "preview",
  ALL: "all",
  LINES: "lines",
  LINES_GRID: "lines-grid",
  TITLES: "titles",
  TITLES_GRID: "titles-grid",
  AIRPORTS: "airports",
  ART: "art",
  FUTURE: "future",
  LEGEND: "legend",
  BADGES: "badges",
};
