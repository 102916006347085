import animateSlides from "./animateSlides";
import isDomElement from "../../utils/isDomElement";
import { SLIDE_TYPES } from "../constants";

const HIDDEN_SLIDES = [
  SLIDE_TYPES.LINES_GRID,
  SLIDE_TYPES.TITLES_GRID,
  SLIDE_TYPES.FUTURE,
];
const CSS_ATTR_CURRENT_SLIDE = "data-current-slide";
const CSS_TRANSFORM_ART = "scale(2) translate(-30%, -11%)";
const CSS_TRANSFORM_LEGEND = "scale(1.5) translate(0%, 0%)";

// showPreview = show all non-hidden layers without map.svg
const showPreview = ({ slides, hiddenSlides }) => {
  slides.forEach(({ img, slideType }) => {
    img.style.transform = "scale(1)";
    if (hiddenSlides.indexOf(slideType) === -1) {
      img.style.opacity = 1;
    } else {
      img.style.opacity = 0;
    }
  });
};

// showAll = only preview slide (map.svg)
const showAll = ({ slides }) => {
  slides.forEach(({ img, slideType }) => {
    img.style.transform = "scale(1)";
    if (slideType === SLIDE_TYPES.PREVIEW) {
      img.style.opacity = 1;
    } else {
      img.style.opacity = 0;
    }
  });
};

// showCurrent = current slide image + preview slide (map.svg) with opacity
const showCurrent = ({
  slides,
  currentType,
  visibleSlides = [],
  hiddenSlides = HIDDEN_SLIDES,
  showOpacityValue = 1,
  fadeOpacityValue = 0.2,
  onEach = Function.prototype,
}) => {
  slides.forEach(({ img, slideType }) => {
    if (slideType === SLIDE_TYPES.FUTURE) {
      img.style.transform = CSS_TRANSFORM_LEGEND;
    } else {
      img.style.transform = "scale(1)";
    }
    if (hiddenSlides.indexOf(slideType) === -1) {
      if ([currentType].concat(visibleSlides).indexOf(slideType) > -1) {
        img.style.opacity = showOpacityValue;
      } else if (slideType === SLIDE_TYPES.PREVIEW) {
        img.style.opacity = fadeOpacityValue;
      } else {
        img.style.opacity = 0;
      }
    } else {
      img.style.opacity = 0;
    }

    onEach({ img, slideType });
  });
};

let currentSlideType = null;

export function initSlides({ slides, slideAttribute }) {
  return animateSlides({
    slides,
    slideAttribute,
    onInersect: ({ currentType }) => {
      if (currentSlideType === currentType) {
        return;
      }

      currentSlideType = currentType;
      document.body.setAttribute(CSS_ATTR_CURRENT_SLIDE, currentType);

      switch (currentType) {
        case SLIDE_TYPES.PREVIEW: {
          showPreview({
            slides,
            hiddenSlides: HIDDEN_SLIDES.concat([
              SLIDE_TYPES.TITLES,
              SLIDE_TYPES.AIRPORTS,
              SLIDE_TYPES.ART,
            ]),
          });
          break;
        }
        case SLIDE_TYPES.BADGES: {
          showAll({
            slides,
          });
          break;
        }
        case SLIDE_TYPES.ALL:
          showAll({
            slides,
          });
          break;
        case SLIDE_TYPES.LINES_GRID:
          showCurrent({
            slides,
            currentType,
            showOpacityValue: 1,
            hiddenSlides: [SLIDE_TYPES.TITLES_GRID, SLIDE_TYPES.FUTURE],
          });
          break;
        case SLIDE_TYPES.TITLES_GRID: {
          showCurrent({
            slides,
            currentType,
            showOpacityValue: 1,
            hiddenSlides: [SLIDE_TYPES.LINES_GRID, SLIDE_TYPES.FUTURE],
          });
          break;
        }
        case SLIDE_TYPES.ART: {
          showCurrent({
            slides,
            currentType,
            onEach: ({ img }) => {
              img.style.transform = "scale(1)";
              img.style.transform = CSS_TRANSFORM_ART;
            },
          });
          break;
        }
        case SLIDE_TYPES.LEGEND: {
          showCurrent({
            slides,
            currentType,
            onEach: ({ img }) => {
              img.style.transform = CSS_TRANSFORM_LEGEND;
            },
          });
          break;
        }
        case SLIDE_TYPES.FUTURE: {
          showCurrent({
            slides,
            currentType,
            hiddenSlides: [SLIDE_TYPES.LINES_GRID, SLIDE_TYPES.TITLES_GRID],
            fadeOpacityValue: 0,
            onEach: ({ img }) => {
              img.style.transform = "scale(1)";
            },
          });
          break;
        }
        default:
          showCurrent({
            slides,
            currentType,
          });
          break;
      }
    },
    onDestroy: () => {
      slides.forEach((slide) => {
        if (isDomElement(slide.img)) {
          slide.img.removeAttribute("style");
        }
      });
    },
  });
}
